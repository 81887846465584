import ThemeContext from "@hig/theme-context";
import theme from "@hig/theme-data/build/esm/lightGrayHighDensityTheme";
import { ThemeProvider } from "@mui/material";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import { Provider as ReduxProvider } from "react-redux";
import { ApplicationContext, Environment, fetchEnvironment } from "./ApplicationContext";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { AlertProvider } from "./providers/AlertProvider";
import SearchManagerProvider from "./providers/SearchManagerProvider";
import { refreshToken } from "./redux/authSlice";
import store from "./redux/store";
import SchemaRouter from "./SchemaRouter";
import { FsrMuiTheme } from "./themes/FsrMuiTheme";
import { beginRefreshToken } from "./utils/authentication";

import "./SchemaPortalApp.css";
import "@hig/fonts/build/ArtifaktElement400.css";
import "@hig/fonts/build/ArtifaktElement600.css";
import "@hig/fonts/build/ArtifaktElement700.css";
import "@hig/fonts/build/ArtifaktElement800.css";

interface QueryErrorType {
    status?: number;
    error?: { errorCode?: string };
}

const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error: unknown) => {
            const typedError = error as QueryErrorType;
            // Ensure 'error' is an instance of 'ExtendedErrorResponse' object.
            // Also, ensure that the 401 is due to expired token and not due to
            // "ACM-002: User or Client is not authorized for this operation."
            if (typedError) {
                if (typedError["status"] === 401) {
                    const innerError = typedError["error"];
                    if (innerError && innerError["errorCode"] === "AUTH-006") {
                        const { auth } = store.getState();
                        if (auth.isLogin && auth.refreshToken) {
                            beginRefreshToken(auth.refreshToken).then((tokenData) => {
                                tokenData && store.dispatch(refreshToken(tokenData));
                            });
                        }
                    }
                } else if (typedError["status"] === 431) {
                    // 431 Request Header Fields Too Large, this happens when there
                    // are too many cookies in the request. This is a known issue by
                    // our analytics code. Hooks like useOrganizations() will receive
                    // the error and display an alert to get user to clear cookies.
                }
            }
        },
    }),
});

export default function SchemaPortalApp(): JSX.Element {
    const [environment, setEnvironment] = React.useState<Environment>();

    React.useEffect(() => {
        fetchEnvironment().then((env: Environment) => setEnvironment(env));

        // Perform a one-time downloading of icon resources that are required
        // by UI components such as <Select> (its 'down caret' part). See the
        // "How to consume icons in HTML/CSS" section in the following document:
        //
        //      https://pages.git.autodesk.com/dpe/digital-hig/#/ref-app/icons
        //
        fetch("/ui-controls.svg").then((response) => {
            if (response.ok) {
                response.text().then((contents) => {
                    const container = document.getElementById("svg-container");
                    if (container) {
                        container.innerHTML = contents;
                    }
                });
            }
        });
    }, []);

    if (!environment) {
        return <></>; // Still fetching environment
    }

    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen />
            <ApplicationContext.Provider value={{ env: environment }}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <ReduxProvider store={store}>
                    <SearchManagerProvider>
                        <ThemeContext.Provider value={theme}>
                            <ThemeProvider theme={FsrMuiTheme}>
                                <div style={{ display: "none" }} id="svg-container"></div>
                                <AlertProvider>
                                    <ErrorBoundary>
                                        <SchemaRouter />
                                    </ErrorBoundary>
                                </AlertProvider>
                            </ThemeProvider>
                        </ThemeContext.Provider>
                    </SearchManagerProvider>
                </ReduxProvider>
            </ApplicationContext.Provider>
        </QueryClientProvider>
    );
}
