import RequestData from "./RequestData";
import { appEnvironment } from "../../ApplicationContext";
import { ExtendedErrorResponse } from "../../types/ErrorResponse";

export async function callGetApi<ResponseType>(path: string, token: string, version?: number): Promise<ResponseType> {
    let baseUrl = appEnvironment.REACT_APP_SCHEMA_BASEURL_V3;

    if (version == 1) {
        baseUrl = appEnvironment.REACT_APP_SCHEMA_BASEURL;
    } else if (version == 2 || version == 3) {
        baseUrl = appEnvironment[`REACT_APP_SCHEMA_BASEURL_V${version}`];
    }

    const response = await fetch(`${baseUrl}${path}`, {
        method: "GET",
        mode: "cors",
        credentials: "include",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
    });

    if (response.ok) {
        const data = await response.json();
        return data as ResponseType;
    }

    let potentialJsonError = {};

    try {
        // In scenarios of a failure, the response body might still be a JSON object
        // (e.g. AUTH-006) although it most likely will be an empty response. In the event
        // that the response is a valid JSON object, we will simply pass that on through
        // the extended error response so that it can potentially be handled by the caller.
        potentialJsonError = await response.json();
    } catch (_) {
        // Response body is not a JSON object, do nothing about it.
    }

    const error: ExtendedErrorResponse = {
        status: response.status,
        statusText: response.statusText,
        error: potentialJsonError,
    };

    throw error;
}

export async function callPostApi<PayloadType, ResponseType>(
    path: string,
    token: string,
    payload: PayloadType
): Promise<ResponseType> {
    const baseUrl = appEnvironment.REACT_APP_SCHEMA_BASEURL_V3;

    try {
        const response = await fetch(`${baseUrl}${path}`, {
            method: "POST",
            mode: "cors",
            credentials: "include",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(payload),
        });

        const data = await response.json();

        if (response.ok) {
            return data as ResponseType;
        }

        throw data;
    } catch (_error) {
        console.log(_error);
        throw _error;
    }
}

export async function callPutApi(path: string, token: string, requestBody: RequestData): Promise<Response> {
    const baseUrl = appEnvironment.REACT_APP_SCHEMA_BASEURL_V3;

    try {
        const response = await fetch(`${baseUrl}${path}`, {
            method: "PUT",
            mode: "cors",
            credentials: "include",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(requestBody),
        });

        const data = await response.json();

        if (response.ok) {
            return data;
        }
        throw data;
    } catch (_error) {
        console.log(_error);
        throw _error;
    }
}

export async function callPatchApi(path: string, token: string, requestBody: RequestData): Promise<Response> {
    const baseUrl = appEnvironment.REACT_APP_SCHEMA_BASEURL_V3;

    try {
        const response = await fetch(`${baseUrl}${path}`, {
            method: "PATCH",
            mode: "cors",
            credentials: "include",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
            body: JSON.stringify(requestBody),
        });

        const data = await response.json();

        if (response.ok) {
            return data;
        }
        throw data;
    } catch (_error) {
        console.log(_error);
        throw _error;
    }
}
