import CaretDownIcon from "@digital-hig/icon/lib/build/icons/utility/caret-down";
import CaretUpIcon from "@digital-hig/icon/lib/build/icons/utility/caret-up";
import { Icon, Menu, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import {
    selectBtnStyle,
    selectMenuListStyle,
    selectMenuPaperStyle,
    selectMenuStyle,
    selectTextStyle,
} from "./stylesheet";

import SwitcherMenuItem from "./SwitcherMenuItem";
import { useOrganization, useOrganizations } from "../../../hooks";
import useGlobalAlert from "../../../hooks/useGlobalAlert";
import { ExtendedErrorResponse } from "../../../types/ErrorResponse";
import { Organization } from "../../../types/Organization";

export default function OrganizationSwitcher(): JSX.Element {
    const { t } = useTranslation();
    const alerts = useGlobalAlert();
    const navigate = useNavigate();
    const { organizationId } = useParams();
    const [anchorElSelect, setAnchorElSelect] = React.useState<null | HTMLElement>(null);
    const { data: organizations, error } = useOrganizations();
    const { data: selectedOrg } = useOrganization(organizationId || "autodesk");

    const handleMenuClicked = (selectedName: string) => {
        setAnchorElSelect(null);

        if (selectedName == "autodesk") {
            navigate("/home");
        } else {
            navigate(`/organization/${selectedName}`);
        }
    };

    const handleOpenSelector = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElSelect(event.currentTarget);
    };

    const handleCloseSelector = () => {
        setAnchorElSelect(null);
    };

    function renderMenu(): JSX.Element {
        return (
            <Menu
                sx={selectMenuStyle}
                id="menu-appbar-profile"
                anchorEl={anchorElSelect}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={Boolean(anchorElSelect)}
                onClose={handleCloseSelector}
                PaperProps={selectMenuPaperStyle}
                MenuListProps={selectMenuListStyle}
                autoFocus={false}
            >
                {organizations?.map((org: Organization) => (
                    <SwitcherMenuItem
                        key={org.name}
                        organization={org}
                        selected={selectedOrg}
                        handleMenuClicked={handleMenuClicked}
                    />
                ))}
            </Menu>
        );
    }

    React.useEffect(() => {
        if (error) {
            const err = error as ExtendedErrorResponse;
            if (err.status === 431) {
                // 431 Request Header Fields Too Large
                alerts.error(t("alerts.requestHeaderFieldsTooLarge"));
            } else {
                alerts.error(`${err.status} ${err.statusText}`);
            }
        }
    }, [error]);

    return (
        <>
            <Typography
                component="div"
                onClick={handleOpenSelector}
                sx={selectBtnStyle}
                data-testid="organization-switcher"
            >
                <Typography component="span" sx={selectTextStyle}>
                    Organizations
                </Typography>
                {anchorElSelect ? (
                    <Icon id="dhig--icons--utility--caret-up" sx={{ fontSize: 18 }}>
                        <CaretUpIcon />
                    </Icon>
                ) : (
                    <Icon id="dhig--icons--utility--caret-down" sx={{ fontSize: 18 }}>
                        <CaretDownIcon />
                    </Icon>
                )}
            </Typography>
            {renderMenu()}
        </>
    );
}
